import APIService from './APIService';

export default {
  getKpisTotalizadosTodasEmpresas(competenciaDe,competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-kpis-totalizados-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getKpisTotalizados(competencia,regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-kpis-totalizados?competencia=${competencia}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getMetasKpisGeral(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-metas-kpis-geral?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getMetasKpisMunicipios(competencia,regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-metas-kpis-municipios?competencia=${competencia}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  importarMetasKpis(tipoCadastro, arquivoId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/importar-metas-kpis?tipoCadastro=${tipoCadastro}&arquivoId=${arquivoId}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  baixarMetasKpis(tipoCadastro, config) {
    return APIService.apiCall().get(`dashboard-faturamento/baixar-metas-kpis?tipoCadastro=${tipoCadastro}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getKpisTotalgetDadosTipologiaReclamacoesizados(competencia) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-dados-tipologia-reclamacoes?competencia=${competencia}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosTipologiaFaturasSemLeitura(competencia) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-dados-tipologia-faturas-sem-leitura?competencia=${competencia}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosTipologiaAcertosFaturamento(competencia) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-dados-tipologia-acertos-faturamento?competencia=${competencia}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },  
  getDadosReclamacoesAbertas(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-dados-reclamacoes-abertas?competenciaDe=${competenciaDe}&competenciaAte=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosKpisPorCompetencia(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-dados-kpis-por-competencia?competenciaDe=${competenciaDe}&competenciaAte=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getKpisByMunicipio(competencia,regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-faturamento/get-dados-kpis-by-municipio?competencia=${competencia}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
}