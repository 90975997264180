<template>
  <v-container fluid class="mt-5">
    <v-row class="mb-8">
      <v-col>
        <h2>Dashboard de Faturamento</h2>
      </v-col>
    </v-row>

    <v-tabs v-model="tab" fixed-tabs color="primary">
      <v-tab>
        Geral
      </v-tab>
      <v-tab>
        Visão Aneel
      </v-tab>
      <v-tab>
        Municípios
      </v-tab>
    </v-tabs>

    <v-card class="mt-0 mb-5">
      <v-card-title>
        <h4>Parâmetros</h4>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto mt-3" justify="space-between">
          <v-col v-if="tab === 0" cols="12" sm="3">
            <input-month
              label="Competência de"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competenciaDe"
              @monthSelected="setCompetenciaDe"
            />
          </v-col>
          <v-col v-if="tab === 0" cols="12" sm="3">
            <input-month
              label="Competência Até"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competenciaAte"
              @monthSelected="setCompetenciaAte"
            />
          </v-col>
          <v-col v-if="tab !== 0" cols="12" sm="3">
            <input-month
              label="Competência"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competencia"
              @monthSelected="setCompetencia"
            />
          </v-col>
          <v-col cols="12" sm="5" md="3">
            <v-btn color="primary" class="mb-4" @click="dialogMetasKpis = true">
              Gerenciar metas dos KPIs
              <v-icon class="ml-2">
                mdi-open-in-app
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="tab === 2" class="mx-auto" justify="space-between">
          <v-col cols="12" sm="5" md="3">
            <v-select
              :items="regionaisList"
              v-model="regionalSelecionada"
              label="Regional selecionada"
            />
          </v-col>
          <v-col cols="12" sm="5" md="3">
            <v-autocomplete
              :items="municipios"
              v-model="municipioSelecionado"
              label="Município selecionado"
              clearable
            />
          </v-col>
          <v-col cols="12" sm="5" md="3">
            <v-select
              :items="listaKpisOrdenacao"
              v-model="kpiOrdenacao"
              label="Ordem Gráficos"
              :append-icon="iconTipoOrdenacao"
              @click:append="
                tipoOrdenacao === 'asc'
                  ? (tipoOrdenacao = 'desc')
                  : (tipoOrdenacao = 'asc')
              "
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dashboard-faturamento-geral
      v-if="tab === 0 && competenciaDe != '' && competenciaAte != ''"
      :activatedTab="tab"
      :traducaoIndicadores="traducaoIndicadores"
      @updateLoadingDados="loadingDados = $event"
      :competenciaDe="competenciaDe"
      :competenciaAte="competenciaAte"
    />

    <dashboard-faturamento-visao-aneel
      v-if="tab === 1 && competencia != ''"
      :competencia="competencia"
      :categories="categories"
      :activatedTab="tab"
      :traducaoIndicadores="traducaoIndicadores"
      @updateLoadingDados="loadingDados = $event"
    />

    <dashboard-faturamento-visao-municipios
      v-if="tab === 2 && competencia != ''"
      :competencia="competencia"
      :categories="categories"
      :municipios="municipios"
      :municipioSelecionado="municipioSelecionado"
      :tipoOrdenacao="tipoOrdenacao"
      :kpiOrdenacao="kpiOrdenacao"
      :activatedTab="tab"
      :traducaoIndicadores="traducaoIndicadores"
      :regionalSelecionada="regionalSelecionadaId"
      @updateLoadingDados="loadingDados = $event"
    />

    <v-overlay :value="loadingDados" :opacity="0.85">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>

    <dialog-gerenciamento-metas-kpis
      v-if="dialogMetasKpis"
      :dialog.sync="dialogMetasKpis"
      ref="metasKpis"
      @importaMetasKpis="importaMetasKpis()"
      @baixarMetasKpis="baixarMetasKpis($event)"
    >
      <template v-slot:cadatro-geral-extra-info>
        Deve conter as colunas:
        <span class="font-weight-bold">mes; ano; qrt; prp; psl; prf; pcf</span>
      </template>
      <template v-slot:cadatro-municipios-extra-info>
        Deve conter as colunas:
        <span class="font-weight-bold"
          >codigo_municipio; mes; ano; qrt; prp; psl; prf; pcf</span
        >
      </template>
    </dialog-gerenciamento-metas-kpis>
  </v-container>
</template>

<script>
  import { generateMonthlyDateRangeArray } from "@/utils/dateUtils.js";

  import dayjs from "dayjs";

  import MunicipiosService from "@/services/MunicipiosService";
  import DashboardFaturamentoService from "@/services/DashboardFaturamentoService";
  import IndicadoresComerciaisV2Service from "@/services/IndicadoresComerciaisV2Service.js";

  export default {
    name: "DashboardFaturamento",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
      DashboardFaturamentoGeral: () =>
        import(
          "@/components/dashboardFaturamento/DashboardFaturamentoGeral.vue"
        ),
      DashboardFaturamentoVisaoAneel: () =>
        import(
          "@/components/dashboardFaturamento/DashboardFaturamentoVisaoAneel.vue"
        ),
      DashboardFaturamentoVisaoMunicipios: () =>
        import(
          "@/components/dashboardFaturamento/DashboardFaturamentoVisaoMunicipios.vue"
        ),
      DialogGerenciamentoMetasKpis: () =>
        import(
          "@/components/paineisGerenciais/DialogGerenciamentoMetasKpis.vue"
        ),
    },
    data: () => ({
      tab: 1,
      competencia: "",
      competenciaDe: "",
      competenciaAte: "",
      loadingDados: true,
      dialogMetasKpis: false,
      municipios: [],
      municipioSelecionado: null,
      regionais: [],
      regionalSelecionada: null,
      listaKpisOrdenacao: [
        { text: "QRT", value: "qrt" },
        { text: "PRP", value: "prp" },
        { text: "PSL", value: "psl" },
        { text: "PRF", value: "prf" },
        { text: "PCF", value: "pcf" },
      ],
      kpiOrdenacao: "qrt",
      tipoOrdenacao: "desc",
      traducaoIndicadores: {
        qrt: "Quantidade relativa de reclamações totais",
        prp: "Percentual de Reclamações Procedentes",
        psl: "Percentual de Faturas Emitidas Sem Leitura",
        prf: "Percentual de Refaturamentos",
        pcf: "Percentual de Acertos de Faturamento",
      },
    }),
    computed: {
      regionaisList() {
        return this.regionais.map((el) => el.nome);
      },
      categories() {
        const startDate = "2023-01";
        const endDate = dayjs().format("YYYY-MM");

        return generateMonthlyDateRangeArray(startDate, endDate, "YYYY-MM");
      },
      regionalSelecionadaId() {
        let regional = this.regionais.find(
          (element) => element.nome == this.regionalSelecionada
        );
        return regional == undefined ? 0 : regional.id;
      },
      iconTipoOrdenacao() {
        if (this.tipoOrdenacao === "asc") return "mdi-sort-reverse-variant";
        else if (this.tipoOrdenacao === "desc") return "mdi-sort-variant";
      },
    },
    async created() {
      let competencia = await IndicadoresComerciaisV2Service.getUltimaCompetencia();
      const competenciaRecente = dayjs(competencia.max_competencia).format(
        "YYYY-MM"
      );
      this.setCompetencia(competenciaRecente);
      this.setCompetenciaDe(competenciaRecente);
      this.setCompetenciaAte(competenciaRecente);
      this.getMunicipios();
    },
    methods: {
      setCompetencia(data) {
        this.competencia = data;
      },
      setCompetenciaDe(data) {
        this.competenciaDe = data;
      },
      setCompetenciaAte(data) {
        this.competenciaAte = data;
      },
      getMunicipios() {
        return MunicipiosService.getMunicipios(this.regionalSelecionadaId)
          .then((res) => {
            this.municipios = res.map((el) => el.nome_municipio);
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar municípios.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      importaMetasKpis() {
        const ref = this.$refs.metasKpis;

        ref.loading = true;

        DashboardFaturamentoService.importarMetasKpis(
          ref.tipoDeCadastro,
          ref.lastUploadedFile.id
        )
          .then((res) => {
            this.$toast.success("Metas salvas com sucesso", "", {
              position: "topRight",
            });
            ref.uploadedFiles = [];
            ref.dialogLocal = false;
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao salvar metas", "", {
              position: "topRight",
            });
          })
          .finally(() => (ref.loading = false));
      },
      baixarMetasKpis(tipoDeCadastro) {
        const ref = this.$refs.metasKpis;

        ref.loading = true;

        DashboardFaturamentoService.baixarMetasKpis(tipoDeCadastro, {
          timeout: 60 * 60 * 1000,
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Metas dos KPIs - ${tipoDeCadastro}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao baixar Metas dos KPIs", "", {
              position: "topRight",
            });
          })
          .finally(() => (ref.loading = false));
      },
      getRegionais() {
        return MunicipiosService.getRegionais()
          .then((res) => {
            this.regionais = res;
            if (this.regionalSelecionada == null) {
              this.regionalSelecionada = res.find(
                (el) => el.nome.toLowerCase() == "corporativa"
              ).nome;
            }
          })
          .catch((err) => {
            throw err;
          });
      },
    },
    watch: {
      tab(newValue, oldValue) {
        if (newValue === 2) {
          this.getRegionais();
        }
      },
      regionalSelecionada() {
        this.municipioSelecionado = null;
        this.getMunicipios();
      },
    },
  };
</script>

<style></style>
